<template>
    <div v-if="comp">
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回列表</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>审核材料查看</span>
        </div>
        <div class="cert_stat">
            <div v-if="comp.cert_stat==2" class="flex">
                <div class="flex-1"><span>当前状态：</span><span>验证通过</span></div>
                <div>
                    <span>审核未通过原因：</span>
                    <span>{{comp.cert_result}}</span>
                </div>
            </div>
        </div>
        <div class="block" style="padding:20px;">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="企业基本信息" name="first">
                    <div v-if="comp.cert_stat==2" class="list-item">
                        <div class="header">基本信息</div>
                        <div class="info-list">
                            <div><span>企业号</span>{{comp.comp_code}}</div>
                            <div><span>企业名称</span>{{comp.name}}</div>
                            <div>
                                <span>企业简称</span>
                                <template>{{comp.short_name}}</template>
                            </div>
                            <div>
                                <span>所在地区</span>
                                <template>{{comp.province}}-{{comp.city}}</template>
                            </div>
                            <div>
                                <span>地址</span>
                                <template>{{comp.addr}}</template>
                            </div>
                            <div class="flex">
                                <span>LOGO</span>
                                <div
                                    style="width:140px;height:140px;border:1px dashed rgba(204,204,204,1);overflow: hidden;display: inline-block;">
                                    <img v-if="comp.logo" :src="imgUrl+comp.logo" style="width: 100%;height: 100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="comp.cert_stat==2" class="list-item">
                        <div class="header">营业执照</div>
                        <div class="info-list">
                            <div><span style="width:145px;">统一社会信用代码</span>{{comp.credit_id_code}}</div>
                            <div><span></span>
                                <div class="credit-img">
                                    <img v-if="comp.credit_image" :src="imgUrl+comp.credit_image" style="width: 100%;height: 100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="comp.cert_stat==2" class="list-item">
                        <div class="header">联系人</div>
                        <div class="info-list">
                            <div class="flex flex-align-center">
                                <span>联系人姓名</span>
                                <template>{{comp.contact_name}}</template>
                            </div>
                            <div class="flex flex-align-center">
                                <span>联系人职务</span>
                                <template>{{comp.contact_position}}</template>
                            </div>
                            <div class="flex flex-align-center">
                                <span>联系人手机</span>
                                <template>{{comp.contact_mobile}}</template>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="银行信息" name="second">
                    <div v-if="comp.cert_stat==2" class="list-item">
                        <div class="header">银行信息</div>
                        <div class="info-list">
                            <div><span>对公账号</span>{{comp.bank_id_code}}</div>
                            <div><span>开户支行</span>{{comp.bank_subbranch}}</div>
                            <div><span>开户银行</span>{{comp.bank_name}}</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="法人信息" name="third">
                    <div v-if="comp.cert_stat==2" class="list-item">
                        <div class="header">管理员信息</div>
                        <div class="info-list">
                            <div class="flex flex-align-center">
                                <span>管理员身份</span>
                                <span>企业法定代表人</span>
                                <span style="font-size: 12px;color: #808080;margin-left: 10px;">（签约时，需使用该代表/代理人的手机号码进行签约验证）</span>
                            </div>
                            <div class="flex flex-align-center">
                                <span>姓名</span>
                                <el-input size="small" v-if="editing" style="max-width:350px;" v-model="legal.legal_name"></el-input>
                                <template v-else>{{comp.legal_name}}</template>
                            </div>
                            <div class="flex flex-align-center">
                                <span>联系方式</span>
                                <el-input v-if="editing" size="small" style="max-width:350px;" v-model="legal.legal_mobile"></el-input>
                                <template v-else>{{comp.legal_mobile}}</template>
                            </div>
                            <div class="flex flex-align-center">
                                <span>身份证号</span>
                                <el-input size="small" v-if="editing" style="max-width:350px;" v-model="legal.legal_id_code"></el-input>
                                <template v-else>{{comp.legal_id_code}}</template>
                            </div>
                            <div class="flex">
                                <span>证件照片</span>
                                <el-upload v-if="editing" class="comp-cert-uploader" action="xx" :show-file-list="false"
                                    :accept="uploadAccept" :http-request="uploadLegal" :before-upload="beforeUpload">
                                    <img v-if="legal.legal_id_front" :src="imgUrl+legal.legal_id_front" class="comp-cert-logo">
                                    <i v-else class="el-icon-plus comp-cert-logo-uploader-icon"></i>
                                </el-upload>
                                <div v-else
                                    style="width:160px;height:160px;border:1px dashed rgba(204,204,204,1);overflow: hidden;display: inline-block;">
                                    <img v-if="comp.logo" :src="imgUrl+comp.legal_id_front" style="width: 100%;height: 100%;">
                                </div>
                            </div>
                            <div v-if="editing" class="flex flex-justify-around">
                                <el-button size="small" @click="editing=false">取消</el-button>
                                <el-button size="small" type="primary" @click="save">保存</el-button>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    export default {
        components: {
            
        },
        data() {
            return {
                activeName: 'first',
                comp:{
                    cert_stat:2,
                    cert_result:"对公账户有误",
                },
            }
        },
        created() {

        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.back();
                this.$message.success("提交成功，请等待审核结果");
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .cert_stat {
        padding: 15px 30px;
        background: #fff;
        margin: 20px;
    }
    .cert_stat span {
        font-size: 15px;
        color: #333;
    }
    .list-item {
        padding-bottom:20px;
        border-bottom:2px solid #999;
    }
    .list-item:last-child {
        border-bottom:none;
    }
    .list-item .header {
        padding: 20px 30px 10px 30px;
        border-bottom:none;
    }
    .info-list>div {
        padding: 10px;
    }
    .info-list>div>span:first-child {
        width: 80px;
        text-align: right;
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        color: #4D4D4D;
    }
    .credit-img{
        width:200px;
        height:260px;
        border:1px dashed rgba(204,204,204,1);
        overflow: hidden;
        display: inline-block;
        /* background: url('../../assets/images/yyzz.png') no-repeat center center; */
    }
</style>